import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import css from './index.module.css';

export default function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <div className={css.faqItem} onClick={() => setIsOpen(!isOpen)}>
      <span className={css.question}>{question} {isOpen ? <i class="fi fi-rr-angle-small-up"></i> : <i class="fi fi-rr-angle-small-down"></i>}</span>
      <div 
        className={css.answerWrapper} 
        style={{ height: `${height}px` }}
      >
        <p ref={contentRef} className={css.answer}>{answer}</p>
      </div>
    </div>
  );
}