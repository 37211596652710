import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import css from './index.module.css';

export default function InfoBlock({ title, desc, link, imgSrc, children }) {
  return (
    <div className={css.infoBlock}>
        <div className={css.infoBlockTexts}>
            <h3>{title}</h3>
            <a className={css.desc}>{desc}</a>
            <Link className={css.toLink} to={link}>Узнать больше <i class="fi fi-rr-arrow-up-right" style={{ fontSize: 11, top: 5 }}></i></Link>
        </div>
        {imgSrc && <img className={css.infoBlockImage} src={imgSrc} /> }
        {children && children}
    </div>
  )
}