import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import css from './index.module.css';
import DemoPopup from "../demoPopup";

export default function PricingBlock({ name, price, desc, children, color, borderColor }) {
  const [showModal, setShowModal] = useState(false);

  return (
  <>
    <DemoPopup showModal={showModal} setShowModal={setShowModal} />

    <div className={css.pricingBlock} style={{ borderColor: borderColor }}>
        <a className={css.tariffName} style={{ color: color }}>{name}</a>
        <h3>{price == -1 ? "Индивидуально" : price === 0 ? "Бесплатно" : price.toLocaleString('ru-RU') + '₽/мес'}</h3>
        <p className={css.tariffDesc}>{desc}</p>
        {price === 0 && <Link className={css.toLink} to={'https://dash.leecyber.com/'}>Перейти<i class="fi fi-rr-arrow-up-right" style={{ fontSize: 11, top: 5 }}></i></Link> }
        {price === -1 && <Link className={css.toLink} onClick={() => setShowModal(true)}>Связаться<i class="fi fi-rr-arrow-up-right" style={{ fontSize: 11, top: 5 }}></i></Link> }
        {price > 0 && <Link className={css.toLink} to={'https://dash.leecyber.com/'}>Подключить<i class="fi fi-rr-arrow-up-right" style={{ fontSize: 11, top: 5 }}></i></Link> }
        
        <div className={css.pluses}>
          {children}
        </div>
    </div>
  </>
  )
}