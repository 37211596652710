export const codeSnippets = {
    0: 
`fetch('https://api.leecyber.com/v2/org/create', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': \`Bearer \${AUTH_TOKEN}\`
  }, 
  body: JSON.stringify({
    'name': "My organization",
    'email': "info@myorg.leecyber.com",
    'phone': "+79939003636",
    'inn': '1234567890',
    'promocode': 'test'
  })
})`,
    1: 
`terraform {
  required_providers {
    lc = {
      source = "tf.leecyber.com/api"
    }
  }
  required_version = ">= 1.1.1"
}

provider "lc" {
  token = "LEECYBER_AUTH_TOKEN"
}

data "lc_configurator" "configurator" {
  name = "My organization"
}`,
    2: 
`lc org info
lc server list
lc projects list
lc status list`
}

export const codeIcons = {
  0: <i class="fi fi-rr-square-code"></i>,
  1: <i class="fi fi-rr-hdd"></i>, 
  2: <i class="fi fi-rr-square-terminal"></i>
}