import React, { useState, useEffect } from "react";
import css from './index.module.css';

export default function PromoBlock({ icon, title, desc }) {
  return (
    <div className={css.promoBlock}>
        {icon}
        <b>{title}</b>
        <a>{desc}</a>
    </div>
  )
}