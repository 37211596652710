import React, { useState } from "react";
import MainContent from "@widgets/content";
import css from './index.module.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";
import PromoBlock from "@widgets/promoBlock";
import FAQItem from "@widgets/faq";

import LogoDash from '@assets/logo/dash.png';
import LogoCA from '@assets/logo/ca.png';
import Logo from '@assets/logo/leecyber.png';
import SEO from '@widgets/seo';
import DemoPopup from '@widgets/demoPopup';

export default function CorpForge() {
  const [showModal, setShowModal] = useState(false);
  
  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />

      <SEO 
        title="CorpForge"
        description="Электронный документооборот внутри компании."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/corpforge"
        favicon={LogoCA}
      />

      <div className={css.rbg} />
      <div className={css.hello}>
        <a className={css.serviceName}><img src={Logo} width={50} style={{ borderRadius: 10 }} /><img src={LogoDash} width={50} style={{ borderRadius: 10 }} /><img src={LogoCA} width={50} style={{ borderRadius: 10 }} /></a>
        <h1>Корпоративные политики</h1>
        <a>Электронный документооборот внутри компании</a>

        <div className={css.btns}>
           <Link className={css.connect} to={`https://dash.leecyber.com/`}>
            Попробовать сейчас
          </Link>
          <a onClick={() => setShowModal(true)}>Enterprise компаниям</a>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/corpforge.png'} />

        <div className={css.promoInfo}>
          <PromoBlock icon={<i class="fi fi-rr-signature"></i>} title="Согласия" desc="Сотрудники компании могут в один клик подписать документ через УНЭП." />
          <PromoBlock icon={<i class="fi fi-rr-memo-circle-check"></i>} title="Ознакомительные" desc="Вы можете публиковать документы с обязательным ознакомлением." />
          <PromoBlock icon={<i class="fi fi-rr-poll-h"></i>} title="Информационные" desc="Обычный документ без надобности в ознакомлении или согласии." />
        </div>
      </div>

      <div className={css.faq}>
        <h2>Отвечаем на вопросы</h2>

        <div className={css.faqItems}>
          <FAQItem 
            question="Как зарегистрироваться?" 
            answer="Зарегистрироваться в Dashboard можно с помощью вашего аккаунта LeeCyber.ID. После регистрации вы сможете создать организацию, чтобы объединить пользователей и управлять доступами." 
          />

          <FAQItem 
            question="Есть ли API?" 
            answer="Да, с его помощью можно интегрировать сервисы компании с другими системами." 
          />

          <FAQItem 
            question="Какой SLA у сервиса?" 
            answer="SLA на доступность сервиса Dashboard — 99,9%. Подробнее ознакомиться с условиями SLA можно в юридических документах." 
          />

          <FAQItem 
            question="Как перенести данные из других систем в Dashboard?" 
            answer="Вы можете перенести свои данные к нам при помощи API или разработанного нами инструмента миграции. Мы также можем подобрать партнёра, который проведет миграцию под ключ. Специалисты LeeCyber готовы ответить на ваши вопросы и помочь с миграцией — напишите нам." 
          />
   
          <FAQItem 
            question="Сервис бесплатный?" 
            answer="Да, сервис полностью бесплатный." 
          />
        </div>
      </div>
      <EndBlock />
    </MainContent>
  )
}