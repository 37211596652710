import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";
import PromoBlock from "@widgets/promoBlock";
import PricingBlock from "@widgets/pricing";
import FAQItem from "@widgets/faq";

import LogoWeboffice from '@assets/logo/weboffice.png';
import Logo from '@assets/logo/leecyber.png';
import DemoPopup from '@widgets/demoPopup';

import SEO from '@widgets/seo';

export default function Weboffice() {
  const [showModal, setShowModal] = useState(false);

  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />

      <SEO 
        title="Виртуальный офис"
        description="Корпоративная почта, календарь и сервисы для коммуникации."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/weboffice"
        favicon={LogoWeboffice}
      />

      <div className={css.rbg} />
      <div className={css.hello}>
        <a className={css.serviceName}><img src={Logo} width={50} style={{ borderRadius: 10 }} /><img src={LogoWeboffice} width={50} style={{ borderRadius: 10 }} /></a>
        <h1>Виртуальный офис</h1>
        <a>Корпоративная почта, календарь и сервисы для коммуникации.</a>

        <div className={css.btns}>
           <Link className={css.connect} to={`https://dash.leecyber.com/`}>
            Попробовать сейчас
          </Link>
          <a onClick={() => setShowModal(true)}>Enterprise компаниям</a>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/weboffice.png'} />

        <div className={css.promoInfo}>
          <PromoBlock icon={<i class="fi fi-rr-galaxy-planet"></i>} title="Экосистема" desc="Удобный переход между сервисами экосистемы LeeCyber." />
          <PromoBlock icon={<i class="fi fi-rr-api"></i>} title="API" desc="Интегрируйтесь в сторонние продукты и приложения по API." />
          <PromoBlock icon={<i class="fi fi-rr-shield-check"></i>} title="Безопасность" desc="Закрытый контур организации для безопасности данных." />
        </div>
      </div>

      <div className={css.pricing}>
        <PricingBlock name="Starter" price={0} desc="Для маленьких компаний" children={
          <>
            <a><i class="fi fi-rr-user"></i> 2 почтовых ящика</a>
            <a><i class="fi fi-rr-check-circle"></i> 1 гб/ящик</a>
            <a><i class="fi fi-rr-check-circle"></i> Спам-фильтр</a>
            <a><i class="fi fi-rr-check-circle"></i> Контакты</a>
            <a><i class="fi fi-rr-check-circle"></i> Интеграция с Google и Yandex</a>
            <a><i class="fi fi-rr-check-circle"></i> Почта на вашем домене</a>
            <a><i class="fi fi-rr-check-circle"></i> Базовое шифрование</a>
          </>
        } />

        <PricingBlock name="Teams" price={199} desc="Для средних компаний" color="#5776ba" children={
          <>
            <a><i class="fi fi-rr-user"></i> 10 почтовых ящиков</a>
            <a><i class="fi fi-rr-check-circle"></i> 10 гб/ящик</a>
            <a><i class="fi fi-rr-check-circle"></i> Спам-фильтр</a>
            <a><i class="fi fi-rr-check-circle"></i> Контакты</a>
            <a><i class="fi fi-rr-check-circle"></i> Интеграция с Google и Yandex</a>
            <a><i class="fi fi-rr-check-circle"></i> Почта на вашем домене</a>
            <a><i class="fi fi-rr-check-circle"></i> Базовое шифрование</a>
          </>
        } />

        <PricingBlock name="Business" price={1999} desc="Для крупных компаний" color="#5776ba" borderColor="#5776ba" children={
          <>
            <a><i class="fi fi-rr-user"></i> 50 почтовых ящиков</a>
            <a><i class="fi fi-rr-check-circle"></i> 20 гб/ящик</a>
            <a><i class="fi fi-rr-check-circle"></i> Спам-фильтр</a>
            <a><i class="fi fi-rr-check-circle"></i> Контакты</a>
            <a><i class="fi fi-rr-check-circle"></i> Интеграция с Google и Yandex</a>
            <a><i class="fi fi-rr-check-circle"></i> Почта на вашем домене</a>
            <a><i class="fi fi-rr-check-circle"></i> Улучшенное шифрование</a>
            <a><i class="fi fi-rr-check-circle"></i> Рассылки</a>
          </>
        } />
      </div>

      <div className={css.faq}>
        <h2>Отвечаем на вопросы</h2>

        <div className={css.faqItems}>
          <FAQItem 
            question="Как зарегистрироваться?" 
            answer="Выберите один из трёх тарифов для организаций и подключите сервис в Dashboard. Настройте доменное имя и получите доступ к продукту." 
          />

          <FAQItem 
            question="Есть ли API?" 
            answer="Да, с его помощью можно интегрировать сервисы компании с другими системами." 
          />

          <FAQItem 
            question="Какой SLA у сервиса?" 
            answer="SLA на доступность сервиса Weboffice — 99,9%. Подробнее ознакомиться с условиями SLA можно в юридических документах." 
          />

          <FAQItem 
            question="Есть ли мобильное приложение?" 
            answer='Нативного приложения у сервиса нет, однако сайт адаптирован под мобильные устройства. Также вы можете синхронизировать почтовый ящик в любом приложении почтовых сервисов.'
          />
        </div>
      </div>
      <EndBlock />
    </MainContent>
  )
}