import React, { useState, useEffect, useRef } from "react";
import css from './index.module.css';

export default function ScaleImage({ img }) {
  const [transform, setTransform] = useState('perspective(1200px) translateY(-8.16949px) scale(0.959153)');
  const imageRef = useRef(null);
  const thresholdRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!imageRef.current || !thresholdRef.current) return;

      const imageRect = imageRef.current.getBoundingClientRect();
      const thresholdRect = thresholdRef.current.getBoundingClientRect();

      if (imageRect.top >= thresholdRect.top) {
        setTransform('perspective(1200px) translateY(-8.16949px) scale(0.959153)');
      } else {
        setTransform(null);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div ref={thresholdRef} style={{ position: 'fixed', top: '300px' }} /> {/* Пороговый элемент */}
      <img 
        ref={imageRef}
        className={css.dashImg} 
        style={{ transform }} 
        src={img} 
      />
    </>
  );
}