import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";
import PromoBlock from "@widgets/promoBlock";
import FAQItem from "@widgets/faq";

import LogoDash from '@assets/logo/dash.png';
import LogoCloud from '@assets/logo/cloud.png';
import Logo from '@assets/logo/leecyber.png';
import SEO from '@widgets/seo';
import DemoPopup from '@widgets/demoPopup';

export default function Cloud() {
  const [showModal, setShowModal] = useState(false);
  
  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />

      <SEO 
        title="Cloud"
        description="Масштабируемые вычислительные ресурсы по всему миру."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/cloud"
        favicon={LogoCloud}
      />

      <div className={css.rbg} />
      <div className={css.hello}>
        <a className={css.serviceName}><img src={Logo} width={50} style={{ borderRadius: 10 }} /><img src={LogoDash} width={50} style={{ borderRadius: 10 }} /><img src={LogoCloud} width={50} style={{ borderRadius: 10 }} /></a>
        <h1>Облачные сервисы</h1>
        <a>Масштабируемые вычислительные ресурсы по всему миру.</a>

        <div className={css.btns}>
           <Link className={css.connect} to={`https://dash.leecyber.com/`}>
            Попробовать сейчас
          </Link>
          <a onClick={() => setShowModal(true)}>Enterprise компаниям</a>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/cloud.png'} />

        <div className={css.promoInfo}>
          <PromoBlock icon={<i class="fi fi-rr-earth-americas"></i>} title="Распределенная инфраструктура" desc="Наши серверы — ближе к вашим клиентам. Запускайте облачные решения в Азии, Европе и России." />
          <PromoBlock icon={<i class="fi fi-rr-shield-check"></i>} title="Повышенная безопасность" desc="Защищаем ваши данные согласно 152-ФЗ, PCI DSS и ISO. А с клиентами из ЕС поддерживаем GDPR." />
          <PromoBlock icon={<i class="fi fi-rr-devices"></i>} title="Удобное управление" desc="Панель управления, с которой все по-настоящему просто — даже с экрана мобильного. " />
        </div>
      </div>

      <div className={css.faq}>
        <h2>Отвечаем на вопросы</h2>

        <div className={css.faqItems}>
          <FAQItem 
            question="Как зарегистрироваться?" 
            answer="Зарегистрироваться в Dashboard можно с помощью вашего аккаунта LeeCyber.ID. После регистрации вы сможете создать организацию, чтобы объединить пользователей и управлять доступами." 
          />

          <FAQItem 
            question="Есть ли API?" 
            answer="Да, с его помощью можно интегрировать сервисы компании с другими системами." 
          />

          <FAQItem 
            question="Какой SLA у сервиса?" 
            answer="SLA на доступность сервиса Dashboard — 99,9%. Подробнее ознакомиться с условиями SLA можно в юридических документах." 
          />

          <FAQItem 
            question="Где находятся ваши дата-центры?" 
            answer="Мы размещаем серверы по всему миру: Россия, Польша, Казахстан, Нидерланды." 
          />

          <FAQItem 
            question="Как выбрать конфигурацию сервера?" 
            answer="Если вы не уверены, какой именно сервер вам нужен — напишите нам. Наш сотрудник поможет подобрать подходящее решение. А если потребуется, подключатся и технические специалисты компании." 
          />
        </div>
      </div>
      <EndBlock />
    </MainContent>
  )
}