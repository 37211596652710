import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";
import PromoBlock from "@widgets/promoBlock";
import FAQItem from "@widgets/faq";

import LogoDash from '@assets/logo/dash.png';
import LogoAnalytics from '@assets/logo/analytics.png';
import Logo from '@assets/logo/leecyber.png';
import SEO from '@widgets/seo';
import DemoPopup from '@widgets/demoPopup';

export default function Analytics() {
  const [devId, setDevId] = useState(0);
  const [showModal, setShowModal] = useState(false);

  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />

      <SEO 
        title="Аналитика"
        description="Анализ аудитории, источников, поведения с вашего сайта."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/analytics"
        favicon={LogoAnalytics}
      />

      <div className={css.rbg} />
      <div className={css.hello}>
        <a className={css.serviceName}><img src={Logo} width={50} style={{ borderRadius: 10 }} /><img src={LogoDash} width={50} style={{ borderRadius: 10 }} /><img src={LogoAnalytics} width={50} style={{ borderRadius: 10 }} /></a>
        <h1>Аналитика</h1>
        <a>Анализ аудитории, источников, поведения с вашего сайта.</a>

        <div className={css.btns}>
           <Link className={css.connect} to={`https://dash.leecyber.com/`}>
            Попробовать сейчас
          </Link>
          <a onClick={() => setShowModal(true)}>Enterprise компаниям</a>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/analytics.png'} />

        <div className={css.promoInfo}>
          <PromoBlock icon={<i class="fi fi-rr-chart-pie"></i>} title="Анализ аудитории" desc="Узнайте своих пользователей - кто они, чем интересуются и что у них общего." />
          <PromoBlock icon={<i class="fi fi-rr-chart-set-theory"></i>} title="Анализ поведения" desc="Изучайте поведение пользователей - что их приводит к целевым действиям." />
          <PromoBlock icon={<i class="fi fi-rr-chat-arrow-grow"></i>} title="Анализ источников" desc="Оценивайте эффективность и ROMI по любым каналам привлечения трафика." />
        </div>
      </div>

      <div className={css.blockInfo}>
        <div style={{ flex: 1, marginBottom: 20 }}>
          <h2>Возможности</h2>
        </div>

        <div className={css.dev}>
          <div className={css.devMenu}>
            <div className={devId === 0 && css.active} onClick={() => setDevId(0)}>Общая аналитика</div>
            <div className={devId === 1 && css.active} onClick={() => setDevId(1)}>Пользователи</div>
          </div>

          <div>
            {devId === 0 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/analytics.png" />}
            {devId === 1 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/analytics-user.png" />}
          </div>
        </div>
      </div>

      <div className={css.faq}>
        <h2>Отвечаем на вопросы</h2>

        <div className={css.faqItems}>
          <FAQItem 
            question="Как зарегистрироваться?" 
            answer="Зарегистрироваться в Dashboard можно с помощью вашего аккаунта LeeCyber.ID. После регистрации вы сможете создать организацию, чтобы объединить пользователей и управлять доступами." 
          />

          <FAQItem 
            question="Есть ли API?" 
            answer="Да, с его помощью можно интегрировать сервисы компании с другими системами." 
          />

          <FAQItem 
            question="Какой SLA у сервиса?" 
            answer="SLA на доступность сервиса Dashboard — 99,9%. Подробнее ознакомиться с условиями SLA можно в юридических документах." 
          />

          <FAQItem 
            question="Как перенести данные из других систем в Dashboard?" 
            answer="Вы можете перенести свои данные к нам при помощи API или разработанного нами инструмента миграции. Мы также можем подобрать партнёра, который проведет миграцию под ключ. Специалисты LeeCyber готовы ответить на ваши вопросы и помочь с миграцией — напишите нам." 
          />
   
          <FAQItem 
            question="Сервис бесплатный?" 
            answer="Да, на данный момент сервис в стадии Preview, поэтому он не тарифицируется." 
          />
        </div>
      </div>
      <EndBlock />
    </MainContent>
  )
}