import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import MainContent from "@widgets/content";
import css from './index.module.css';

import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";
import PromoBlock from "@widgets/promoBlock";
import FAQItem from "@widgets/faq";
import PricingBlock from "@widgets/pricing";

import LogoDocs from '@assets/logo/docs.png';
import Logo from '@assets/logo/leecyber.png';
import SEO from '@widgets/seo';
import DemoPopup from '@widgets/demoPopup';

export default function Docs() {
  const [showModal, setShowModal] = useState(false);

  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />

      <SEO 
        title="Docs"
        description="Создавайте документы из готовых шаблонов, составленных юристами."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/docs"
        favicon={LogoDocs}
      />

      <div className={css.rbg} />
      <div className={css.hello}>
        <a className={css.serviceName}><img src={Logo} width={50} style={{ borderRadius: 10 }} /><img src={LogoDocs} width={50} style={{ borderRadius: 10 }} /></a>
        <h1>Документооборот</h1>
        <a>Создавайте документы из готовых шаблонов</a>

        <div className={css.btns}>
           <Link className={css.connect} to={`https://dash.leecyber.com/`}>
            Попробовать сейчас
          </Link>
          <a onClick={() => setShowModal(true)}>Enterprise компаниям</a>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/corpforge.png'} />

        <div className={css.promoInfo}>
          <PromoBlock icon={<i className="fi fi-rr-bolt"/>} title="Безупречный документ" desc="Без ошибок, с корректными данными и идеальным форматированием." />
          <PromoBlock icon={<i className="fi fi-rr-bolt"/>} title="Сократите цикл сделки" desc="Подготовка и согласование документации проходят в 2 раза быстрее." />
          <PromoBlock icon={<i className="fi fi-rr-bolt"/>} title="Соответствие стандартам" desc="Автоматизация заточена под ваши стандарты документооборота." />
        </div>
      </div>

      <div className={css.pricing}>
        <PricingBlock name="Starter" price={2999} desc="Для маленьких организаций" children={
          <>
            <a><i class="fi fi-rr-user"></i> 5 пользователей</a>
            <a><i class="fi fi-rr-check-circle"></i> Интеллектуальный помощник</a>
            <a><i class="fi fi-rr-check-circle"></i> Рабочее пространство</a>
            <a><i class="fi fi-rr-check-circle"></i> Конструктор документов</a>
            <a><i class="fi fi-rr-check-circle"></i> Интеграция с реестрами ФНС</a>
            <a><i class="fi fi-rr-check-circle"></i> Внутреннее согласование</a>
            <a><i class="fi fi-rr-check-circle"></i> Доступ к REST API</a>
          </>
        } />

        <PricingBlock name="Teams" price={6999} desc="Для средних организаций" color="#5776ba" borderColor="#5776ba" children={
          <>
            <a><i class="fi fi-rr-user"></i> 12 пользователей</a>
            <a><i class="fi fi-rr-check-circle"></i> Интеллектуальный помощник</a>
            <a><i class="fi fi-rr-check-circle"></i> Рабочее пространство</a>
            <a><i class="fi fi-rr-check-circle"></i> Конструктор документов</a>
            <a><i class="fi fi-rr-check-circle"></i> Интеграция с реестрами ФНС</a>
            <a><i class="fi fi-rr-check-circle"></i> Внутреннее согласование</a>
            <a><i class="fi fi-rr-check-circle"></i> Доступ к REST API</a>
            <a><i class="fi fi-rr-check-circle"></i> Настройка помощника</a>
          </>
        } />

        <PricingBlock name="Enterprise" price={-1} desc="Для крупных компаний" color="#ff6969" borderColor="#ff6969" children={
          <>
            <a><i class="fi fi-rr-check-circle"></i> Интеллектуальный помощник</a>
            <a><i class="fi fi-rr-check-circle"></i> Рабочее пространство</a>
            <a><i class="fi fi-rr-check-circle"></i> Конструктор документов</a>
            <a><i class="fi fi-rr-check-circle"></i> Интеграция с реестрами ФНС</a>
            <a><i class="fi fi-rr-check-circle"></i> Внутреннее согласование</a>
            <a><i class="fi fi-rr-check-circle"></i> Workflow</a>
            <a><i class="fi fi-rr-check-circle"></i> Журнал безопасности</a>
            <a><i class="fi fi-rr-check-circle"></i> Брендирование документов</a>
            <a><i class="fi fi-rr-check-circle"></i> Формулировки</a>
          </>
        } />
      </div>

      <div className={css.faq}>
        <h2>Отвечаем на вопросы</h2>

        <div className={css.faqItems}>
          <FAQItem 
            question="Как зарегистрироваться?" 
            answer="Зарегистрироваться в Dashboard можно с помощью вашего аккаунта LeeCyber.ID. После регистрации вы сможете создать организацию, чтобы объединить пользователей и управлять доступами." 
          />

          <FAQItem 
            question="Есть ли API?" 
            answer="Да, с его помощью можно интегрировать сервисы компании с другими системами." 
          />

          <FAQItem 
            question="Какой SLA у сервиса?" 
            answer="SLA на доступность сервиса Dashboard — 99,9%. Подробнее ознакомиться с условиями SLA можно в юридических документах." 
          />

          <FAQItem 
            question="Как перенести данные из других систем в Dashboard?" 
            answer="Вы можете перенести свои данные к нам при помощи API или разработанного нами инструмента миграции. Мы также можем подобрать партнёра, который проведет миграцию под ключ. Специалисты LeeCyber готовы ответить на ваши вопросы и помочь с миграцией — напишите нам." 
          />
        </div>
      </div>
      <EndBlock />
    </MainContent>
  )
}