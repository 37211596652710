import React from "react";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import MainContent from "@widgets/content";
import css from './index.module.css';

export default function ErrorPage() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(`/`);
  };

  return (
    <MainContent>
      <div className={css.hello}>
        <h1>Ошибка 404</h1>
        <a>Нет такой страницы</a>

        <div className={css.btns}>
          <div className={css.connect} onClick={handleRedirect}>
            Перейти на главную
          </div>
        </div>
      </div>
    </MainContent>
  )
}