import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';
import SEO from '@widgets/seo';

export default function Blog() {
  const navigate = useNavigate();
  const [news, setNews] = useState([]);

  useEffect(() => {
    axios.get(`https://api.leecyber.com/vSite/news?limit=30`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response  => {
      setNews(response.data);
    })
  }, []);

  function convertToDate(isoDate) {
    const date = new Date(isoDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('ru-Ru', options).format(date);
  }
  
  const handleRedirect = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <MainContent>
      <SEO 
        title="Блог"
        description="Последние новости компании LeeCyber."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/blog"
      />

      <div className={css.head}>
          <h1>Блог</h1>
          <a>Последние новости компании</a>
      </div>

      <div className={css.news}>
        {news.map(newsBlock => (
          <div className={css.newsBlock} key={newsBlock.id} onClick={() => handleRedirect(newsBlock.id)}>
            <img src={`https://cdn-a.leecyber.com/news/${newsBlock.image}`} />
            <a>{newsBlock.by} • {convertToDate(newsBlock.createdAt)}</a>
            <h3>{newsBlock.name}</h3>
          </div>
        ))}
      </div>

    </MainContent>
  )
}