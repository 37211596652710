import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";
import PromoBlock from "@widgets/promoBlock";
import PricingBlock from "@widgets/pricing";
import FAQItem from "@widgets/faq";

import LogoDash from '@assets/logo/dash.png';
import LogoGTM from '@assets/logo/gtm.png';
import Logo from '@assets/logo/leecyber.png';
import SEO from '@widgets/seo';
import DemoPopup from '@widgets/demoPopup';

export default function STagManager() {
  const [showModal, setShowModal] = useState(false);

  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />

      <SEO 
        title="sGTM"
        description="Установка Google Tag Manager на вашем поддомене за пару кликов"
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/sgtm"
        favicon={LogoGTM}
      />

      <div className={css.rbg} />
      <div className={css.hello}>
        <a className={css.serviceName}><img src={Logo} width={50} style={{ borderRadius: 10 }} /><img src={LogoDash} width={50} style={{ borderRadius: 10 }} /><img src={LogoGTM} width={50} style={{ borderRadius: 10 }} /></a>
        <h1>Server GTM</h1>
        <a>Установка Google Tag Manager на вашем поддомене за пару кликов</a>

        <div className={css.btns}>
           <Link className={css.connect} to={`https://dash.leecyber.com/analytics/gtm/`}>
            Попробовать сейчас
          </Link>
          <a onClick={() => setShowModal(true)}>Enterprise компаниям</a>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/sgtm.jpg'} />

        <div className={css.promoInfo}>
          <PromoBlock icon={<i class="fi fi-rr-infinity"></i>} title="Безлимит" desc="Нет ограничений на запросы, всё зависит от характеристик сервера." />
          <PromoBlock icon={<i class="fi fi-rr-compress"></i>} title="Контроль" desc="Полный контроль над Google Tag Manager, без опасений санкций и блокировок." />
          <PromoBlock icon={<i class="fi fi-rr-shield-check"></i>} title="Поддержка" desc="Обновим и настроим Google Tag Manager под ваш проект." />
        </div>
      </div>

      <div className={css.pricing}>
        <PricingBlock name="Basic" price={2500} desc="Для небольших компаний." children={
          <>
            <a><i class="fi fi-rr-refresh"></i> Обновления</a>
            <a><i class="fi fi-rr-user-headset"></i> Техническая поддержка</a>
            <a><i class="fi fi-rr-microchip"></i> 2 x 3.3 ГГц CPU</a>
            <a><i class="fi fi-rr-memory"></i> 2 ГБ ОЗУ</a>
            <a><i class="fi fi-rr-disk"></i> 40 ГБ NVMe</a>
            <a><i class="fi fi-rr-cloud-back-up"></i> 1 Гбит/с*</a>
          </>
        } />

        <PricingBlock name="Standart" price={5000} desc="Для средних компаний." color="#5776ba" children={
          <>
            <a><i class="fi fi-rr-refresh"></i> Обновления</a>
            <a><i class="fi fi-rr-user-headset"></i> Техническая поддержка</a>
            <a><i class="fi fi-rr-microchip"></i> 4 x 3.3 ГГц CPU</a>
            <a><i class="fi fi-rr-memory"></i> 8 ГБ ОЗУ</a>
            <a><i class="fi fi-rr-disk"></i> 80 ГБ NVMe</a>
            <a><i class="fi fi-rr-cloud-back-up"></i> 1 Гбит/с*</a>
          </>
        } />

        <PricingBlock name="Business" price={10000} desc="Для крупных компаний." color="#5776ba" borderColor="#5776ba" children={
          <>
            <a><i class="fi fi-rr-refresh"></i> Обновления</a>
            <a><i class="fi fi-rr-user-headset"></i> Техническая поддержка</a>
            <a><i class="fi fi-rr-microchip"></i> 8 x 3.3 ГГц CPU</a>
            <a><i class="fi fi-rr-memory"></i> 16 ГБ ОЗУ</a>
            <a><i class="fi fi-rr-disk"></i> 160 ГБ NVMe</a>
            <a><i class="fi fi-rr-cloud-back-up"></i> 1 Гбит/с*</a>
          </>
        } />

        <PricingBlock name="Enterprise" price={-1} desc="Для корпораций." color="#ff6969" borderColor="#ff6969" children={
          <>
            <a><i class="fi fi-rr-refresh"></i> Обновления</a>
            <a><i class="fi fi-rr-user-headset"></i> Техническая поддержка</a>
            <a><i class="fi fi-rr-boss"></i> Менеджер</a>
            <a><i class="fi fi-rr-database"></i> Подбор сервера</a>
          </>
        } />
      </div>

      <div className={css.faq}>
        <h2>Отвечаем на вопросы</h2>

        <div className={css.faqItems}>
            <FAQItem 
                question="Могут ли заблокировать контейнер?" 
                answer="Нет, мы используем официальный API Google, который не блокируется. Вы также можете установить контейнер за рубежом, дабы обезопасить себя от санкций." 
            />

            <FAQItem 
                question="Можно дать доступ коллегам?" 
                answer="Да, вы можете добавить пользователя в организацию и дать доступ к сервису." 
            />

            <FAQItem 
                question="Есть ли ограничение по запросам?" 
                answer="Нет. Мы устанавливаем Google Tag Manager на ваш сервер, там нет никаких ограничений, кроме серверных характеристик." 
            />
   
            <FAQItem 
                question="Мне помогут?" 
                answer="Да, в нашей команде — профессионалы. Поможем установить, настроить и решить возникающие проблемы." 
            />

            <FAQItem 
                question="Как оплатить?" 
                answer="Вам нужно создать организацию в Dashboard, перейти в раздел «Оплата» и пополнить счёт любым удобным способом." 
            />

            <FAQItem 
                question="Как зарегистрироваться?" 
                answer="Зарегистрироваться в Dashboard можно с помощью вашего аккаунта LeeCyber.ID. После регистрации вы сможете создать организацию, чтобы объединить пользователей и управлять доступами." 
            />
        </div>
      </div>
      <EndBlock />

      <div className={css.faq} style={{ display: 'grid', gap: 10 }}>
        <a style={{ fontSize: 13 }}>¹Сервис может быть недоступен в некоторых регионах. Товарные знаки принадлежат Google LLC. Все права защищены.</a>
        <a style={{ fontSize: 13 }}>²Google Tag Manager сервис компании Google LLC.</a>
        <a style={{ fontSize: 13 }}>*На серверах в Нидерландах установленная пропускная способность — 200 Мбит/c.</a>
      </div>
    </MainContent>
  )
}