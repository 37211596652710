import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import css from './index.module.css';

export default function InstrumentBlock({ name, desc, img, posY, posX, imgSize }) {
  return (
    <div className={css.instrumentBlock} style={{ backgroundImage: `url(${img})`, backgroundPositionY: posY, backgroundPositionX: posX, backgroundSize: imgSize }}>
        <h3>{name}</h3>
        <a>{desc}</a>
    </div>
  )
}