import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link, useParams } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';
import SEO from '@widgets/seo';

export default function BlogPost() {
  let { id } = useParams();
  const [news, setNews] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`https://api.leecyber.com/vSite/news/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response  => {
      setNews(response.data);
      setLoading(false);
    })
  }, [id]);
  
  if (loading) {
    return null;
  }

  function convertToDate(isoDate) {
    const date = new Date(isoDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('ru-Ru', options).format(date);
  }

  return (
    <MainContent>
        <SEO 
          title={news.name || "Новость"}
          description={news.miniDesc || "Новость"}
          ogImage={news.image || "https://cdn-a.leecyber.com/landing/og.png"}
          ogUrl={`https://leecyber.com/blog/${id}`}
        />

        <div className={css.head}>
          <Link to="/blog" className={css.back}><i class="fi fi-rr-angle-small-left"></i> Блог</Link>
          <h1>{news.name}</h1>
          <a>{news.by} • {convertToDate(news.createdAt)}</a>
        </div>

        <div className={css.post}>
            <img src={`https://cdn-a.leecyber.com/news/${news.image}`} />
            <div style={{ marginTop: 20 }} dangerouslySetInnerHTML={{__html: news.content}}></div>
        </div>
    </MainContent>
  )
}