import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';
import InstrumentBlock from './instrumentBlock';
import { codeSnippets, codeIcons } from './code';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

import PromoBlock from "@widgets/promoBlock";
import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";

import SEO from "@widgets/seo";
import DemoPopup from '@widgets/demoPopup';

export default function RootPage() {
  const navigate = useNavigate();
  const [devId, setDevId] = useState(0);
  const [news, setNews] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios.get(`https://api.leecyber.com/vSite/news?limit=3`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response  => {
      setNews(response.data);
    })
  }, []);

  function convertToDate(isoDate) {
    const date = new Date(isoDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('ru-Ru', options).format(date);
  }
  
  const handleRedirect = (link) => {
    navigate(link);
  };

  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />

      <SEO 
        title="Друг вашего бизнеса"
        description="Лучший способ контроля, управления и планирования."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com"
      />

      <div className={css.rbg} />
      <div className={css.hello}>
        <h1>Друг вашего бизнеса</h1>
        <a>Лучший способ контроля, управления и планирования.</a>

        <div className={css.btns}>
          <Link className={css.connect} to={`https://dash.leecyber.com/`}>
            Попробовать сейчас
          </Link>
          <a onClick={() => setShowModal(true)}>Запросить демо</a>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/main.jpg'} />

        <div className={css.promoInfo}>
          <PromoBlock icon={<i class="fi fi-rr-key-skeleton-left-right" />} title="Контроль" desc="Применяйте политики безопасности и контролируйте доступ к отдельным сервисам." />
          <PromoBlock icon={<i class="fi fi-rr-shield-check" />} title="Безопасность" desc="Храним все данные на территории Российской Федерации с учётом законодательных требований." />
          <PromoBlock icon={<i class="fi fi-rr-database" />} title="Отказоустойчивость" desc="Сервисы спроектированы для работы с большим объемом задач. Они справляются с любыми нагрузками." />
        </div>
      </div>

      <div className={css.blockInfo}>
        <h2>Инструменты для разных задач</h2>
        <a>LeeCyber предлагает инструменты и сервисы для комфортной работы и решения разных задач.</a>

        <div className={css.instruments}>
          <InstrumentBlock name={"Консоль управления"} desc={"Удобный инструмент для управления сервисами экосистемы."} img={'https://cdn-a.leecyber.com/landing/cdash.png'} posY={110} posX={26} imgSize={"145%"} />
          <InstrumentBlock name={"Мобильное приложение"} desc={"Возможность управлять сервисами с мобильного телефона."} img={'https://cdn-a.leecyber.com/landing/mobile.png'} posY={110} imgSize={"82%"} />
          <InstrumentBlock name={"Документация"} desc={"Подробные описания, пошаговые инструкции и примеры для работы."} img={'https://cdn-a.leecyber.com/landing/cdocs.png'} posY={110} imgSize={"82%"} />
          <InstrumentBlock name={"Projects"} desc={"Сервис для совместной работы в компании."} img={'https://cdn-a.leecyber.com/landing/cprojects.png'} posY={110} imgSize={"84%"} />
          <InstrumentBlock name={"Status"} desc={"Сервис для сбора, хранения и визуализации метрик с ваших ресурсов."} img={'https://cdn-a.leecyber.com/landing/cstatus.png'} posY={110} imgSize={"84%"} />
          <InstrumentBlock name={"Защищенное хранилище"} desc={"Сервис для хранения важных файлов с неограниченным пространством."} img={'https://cdn-a.leecyber.com/landing/cdrive.png'} posY={100} imgSize={"87%"} />
        </div>
      </div>

      <div className={`${css.blockInfoFlex} ${css.devBlock}`}>
        <div style={{ flex: 1 }}>
          <h2>Автоматизируйте управление архитектурой</h2>
          <a>Мы легко интегрируемся с вашим стеком, не требуя изменений в инфраструктуре. Просто выберите способ интеграции и развертывайте</a>
          <Link className={css.connect} to={`https://docs.leecyber.com/dev`}>{codeIcons[devId]} Попробовать сейчас <i class="fi fi-rr-arrow-up-right" style={{ fontSize: 11, top: 5 }}></i></Link>
        </div>

        <div className={css.dev}>
          <div className={css.devMenu}>
            <div className={devId === 0 && css.active} onClick={() => setDevId(0)}>{codeIcons[0]} API</div>
            <div className={devId === 1 && css.active} onClick={() => setDevId(1)}>{codeIcons[1]} Terraform</div>
            <div className={devId === 2 && css.active} onClick={() => setDevId(2)}>{codeIcons[2]} CLI</div>
          </div>

          <div>
          <SyntaxHighlighter 
            language="javascript" 
            style={vscDarkPlus}
            customStyle={{
              padding: '20px',
              borderRadius: '20px',
              fontSize: '14px',
              backgroundColor: 'rgb(20, 22, 23)',
              border: "1px solid rgb(255 255 255 / 15%)",
              height: 340,
              boxSizing: 'border-box'
            }}
          >
            {codeSnippets[devId]}
          </SyntaxHighlighter>
          </div>
        </div>
      </div>

      <div className={css.blockInfoFlex}>
        <div className={css.dev}>
          <img style={{ borderRadius: 20, border: "1px solid rgb(255 255 255 / 15%)" }} src="https://cdn-a.leecyber.com/landing/support.gif" />
        </div>

        <div style={{ flex: 1 }}>
          <h2>Профессиональная помощь</h2>
          
          <div className={css.helpBlock}>
            <a><i class="fi fi-rr-time-fast"></i> Оперативно и круглосуточно</a>
            <p>Служба поддержки ответит на любые вопросы о работе LeeCyber. В расширенных тарифных планах предлагаем персонального менеджера.</p>
          </div>

          <div className={css.helpBlock}>
            <a><i class="fi fi-rr-bolt"></i> Удобные каналы связи</a>
            <p>Мы есть в Dashboard, Telegram, VK и других социальных платформах.</p>
          </div>

          <div className={css.helpBlock}>
            <a><i class="fi fi-rr-flag-alt"></i> На разных языках</a>
            <p>Инженеры поддержки отвечают на русском, английском, корейском и китайском языках.</p>
          </div>

          <div className={css.connect} style={{ width: 250, marginTop: 30 }} onClick={() => handleRedirect('/about')}>Подробнее о поддержке <i class="fi fi-rr-arrow-up-right" style={{ fontSize: 11, top: 5 }}></i></div>
        </div>
      </div>

      <div className={css.blockInfo}>
        <h2 className={css.newsTitle}>Новости и статьи <div className={css.connect} onClick={() => handleRedirect('/blog')}>Читать блог</div></h2>
          
        <div className={css.news}>
          {news.map(newsBlock => (
            <div className={css.newsBlock} key={newsBlock.id} onClick={() => handleRedirect(`/blog/${newsBlock.id}`)}>
              <img src={`https://cdn-a.leecyber.com/news/${newsBlock.image}`} />
              <a>{newsBlock.by} • {convertToDate(newsBlock.createdAt)}</a>
              <h3>{newsBlock.name}</h3>
            </div>
          ))}
        </div>
      </div>
      <EndBlock />
    </MainContent>
  )
}