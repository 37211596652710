import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";
import PromoBlock from "@widgets/promoBlock";
import FAQItem from "@widgets/faq";

import LogoDash from '@assets/logo/dash.png';
import LogoHRM from '@assets/logo/id.png';
import Logo from '@assets/logo/leecyber.png';

import SEO from '@widgets/seo';
import DemoPopup from '@widgets/demoPopup';

export default function HRM() {
  const [devId, setDevId] = useState(0);
  const [showModal, setShowModal] = useState(false);

  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />

      <SEO 
        title="Projects"
        description="Сервис для совместной работы и организации процессов в компании."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/projects"
        favicon={LogoHRM}
      />

      <div className={css.rbg} />
      <div className={css.hello}>
        <a className={css.serviceName}><img src={Logo} width={50} style={{ borderRadius: 10 }} /><img src={LogoDash} width={50} style={{ borderRadius: 10 }} /><img src={LogoHRM} width={50} style={{ borderRadius: 10 }} /></a>
        <h1>Кадровый учёт</h1>
        <a>Удобный инструмент для автоматизации кадрового учёта.</a>

        <div className={css.btns}>
           <Link className={css.connect} to={`https://dash.leecyber.com/`}>
            Попробовать сейчас
          </Link>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/hrm.png'} />

        <div className={css.promoInfo}>
          <PromoBlock 
            icon={<i class="fi fi-rr-shield-check"></i>} 
            title="Безопасно" 
            desc="Храним все данные на территории Российской Федерации с учётом законодательных требований."
          />
          <PromoBlock 
            icon={<i class="fi fi-rr-binary-lock"></i>} 
            title="Зашифровано" 
            desc="Все данные и файлы в HRM строго зашифрованы. Платформа обеспечивает первый уровень защищенности данных." 
          />
          <PromoBlock 
            icon={<i class="fi fi-rr-free"></i>} 
            title="Бесплатно" 
            desc="Хранение информации в сервисе полностью бесплатное, а файлы загружаются в защищенное Хранилище." 
          />
        </div>
      </div>

      <div className={css.blockInfo}>
        <div style={{ flex: 1, marginBottom: 20 }}>
          <h2>Возможности</h2>
        </div>

        <div className={css.dev}>
          <div className={css.devMenu}>
            <div className={devId === 0 && css.active} onClick={() => setDevId(0)}>Учет личных дел</div>
            <div className={devId === 1 && css.active} onClick={() => setDevId(1)}>Документы</div>
            <div className={devId === 2 && css.active} onClick={() => setDevId(2)}>NDA</div>
          </div>

          <div>
            {devId === 0 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/hrm.png" />}
            {devId === 1 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/hrm-docs.png" />}
            {devId === 2 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/nda.png" />}
          </div>
        </div>
      </div>

      <div className={css.faq}>
        <h2>Отвечаем на вопросы</h2>

        <div className={css.faqItems}>
          <FAQItem 
            question="Как зарегистрироваться?" 
            answer="Зарегистрироваться в Dashboard можно с помощью вашего аккаунта LeeCyber.ID. После регистрации вы сможете создать организацию, чтобы объединить пользователей и управлять доступами." 
          />

          <FAQItem 
            question="Есть ли API?" 
            answer="Да, с его помощью можно интегрировать сервисы компании с другими системами." 
          />

          <FAQItem 
            question="Какой SLA у сервиса?" 
            answer="SLA на доступность сервиса Dashboard — 99,9%. Подробнее ознакомиться с условиями SLA можно в юридических документах." 
          />

          <FAQItem 
            question="Как перенести данные из других систем в Dashboard?" 
            answer="Вы можете перенести свои данные к нам при помощи API или разработанного нами инструмента миграции. Мы также можем подобрать партнёра, который проведет миграцию под ключ. Специалисты LeeCyber готовы ответить на ваши вопросы и помочь с миграцией — напишите нам." 
          />

          <FAQItem 
            question="Сервис полностью бесплатный?" 
            answer="Да, вы можете создать неограниченное количество личных дел, однако файлы хранятся в сервисе Хранилище и тарифицируются согласно тарифам сервиса." 
          />

          <FAQItem 
            question="Есть ли ограничения по объёму хранимой информации?" 
            answer="Ограничений по объему хранимой текстовой информации нет. Файлы хранятся в сервисе Хранилище, к ним применяются квоты Сервиса." 
          />
        </div>
      </div>
      <EndBlock />
    </MainContent>
  )
}