import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import css from './index.module.css';
import DemoPopup from "../demoPopup";

export default function EndBlock() {
  const [showModal, setShowModal] = useState(false);

  return (
  <>
    <DemoPopup showModal={showModal} setShowModal={setShowModal} />
    <div className={css.hello} style={{ marginTop: 100 }}>
        <h2 className={css.endTitle}>Начнём?</h2>
        <a className={css.endDesc}>Зарегистрируйтесь прямо сейчас и получите месяц Business плана</a>

        <div className={css.btns} style={{ alignItems: "left", justifyContent: 'left', marginTop: 30 }}>
          <Link className={css.connect} to={`https://dash.leecyber.com/`}>
            Попробовать сейчас
          </Link>
          <a onClick={() => setShowModal(true)}>Запросить демо</a>
        </div>
    </div>
  </>
  )
}