import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";
import PromoBlock from "@widgets/promoBlock";
import PricingBlock from "@widgets/pricing";
import FAQItem from "@widgets/faq";

import LogoDash from '@assets/logo/dash.png';
import LogoDrive from '@assets/logo/drive.png';
import Logo from '@assets/logo/leecyber.png';
import SEO from '@widgets/seo';
import DemoPopup from '@widgets/demoPopup';

export default function Drive() {
  const [showModal, setShowModal] = useState(false);

  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />

      <SEO 
        title="Хранилище"
        description="Корпоративное хранилище для вашей организации."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/drive"
        favicon={LogoDrive}
      />

      <div className={css.rbg} />
      <div className={css.hello}>
        <a className={css.serviceName}><img src={Logo} width={50} style={{ borderRadius: 10 }} /><img src={LogoDash} width={50} style={{ borderRadius: 10 }} /><img src={LogoDrive} width={50} style={{ borderRadius: 10 }} /></a>
        <h1>Хранилище</h1>
        <a>Корпоративное хранилище для вашей организации.</a>

        <div className={css.btns}>
           <Link className={css.connect} to={`https://dash.leecyber.com/`}>
            Попробовать сейчас
          </Link>
          <a onClick={() => setShowModal(true)}>Enterprise компаниям</a>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/drive.png'} />

        <div className={css.promoInfo}>
          <PromoBlock icon={<i class="fi fi-rr-infinity"></i>} title="Безлимит" desc="Расширять хранилище можно сколько угодно, без ограничений." />
          <PromoBlock icon={<i class="fi fi-rr-compress"></i>} title="Контроль" desc="Контролируйте доступ к файлам и папкам по ролям или отдельным пользователям." />
          <PromoBlock icon={<i class="fi fi-rr-shield-check"></i>} title="Антивирус" desc="Проверяйте файлы с помощью антивируса и нейрофильтра." />
        </div>
      </div>

      <div className={css.pricing}>
        <PricingBlock name="Starter" price={249} desc="Для небольших компаний." children={
          <>
            <a><i class="fi fi-rr-check-circle"></i> 100 гб пространства</a>
            <a><i class="fi fi-rr-check-circle"></i> Защищенный архив</a>
            <a><i class="fi fi-rr-check-circle"></i> Антивирус</a>
            <a><i class="fi fi-rr-check-circle"></i> Возможность увеличения диска</a>
          </>
        } />

        <PricingBlock name="Teams" price={599} desc="Для средних компаний." color="#5776ba" children={
          <>
            <a><i class="fi fi-rr-check-circle"></i> 1 тб пространства</a>
            <a><i class="fi fi-rr-check-circle"></i> Защищенный архив</a>
            <a><i class="fi fi-rr-check-circle"></i> Антивирус</a>
            <a><i class="fi fi-rr-check-circle"></i> Возможность увеличения диска</a>
          </>
        } />

        <PricingBlock name="Business" price={1399} desc="Для крупных компаний." color="#5776ba" borderColor="#5776ba" children={
          <>
            <a><i class="fi fi-rr-check-circle"></i> 3 тб пространства</a>
            <a><i class="fi fi-rr-check-circle"></i> Защищенный архив</a>
            <a><i class="fi fi-rr-check-circle"></i> Антивирус</a>
            <a><i class="fi fi-rr-check-circle"></i> Возможность увеличения диска</a>
          </>
        } />
      </div>

      <div className={css.faq}>
        <h2>Отвечаем на вопросы</h2>

        <div className={css.faqItems}>
          <FAQItem 
            question="Как зарегистрироваться?" 
            answer="Зарегистрироваться в Dashboard можно с помощью вашего аккаунта LeeCyber.ID. После регистрации вы сможете создать организацию, чтобы объединить пользователей и управлять доступами." 
          />

          <FAQItem 
            question="Есть ли API?" 
            answer="Да, с его помощью можно интегрировать сервисы компании с другими системами." 
          />

          <FAQItem 
            question="Какой SLA у сервиса?" 
            answer="SLA на доступность сервиса Dashboard — 99,9%. Подробнее ознакомиться с условиями SLA можно в юридических документах." 
          />

          <FAQItem 
            question="Есть ли бесплатное пространство?" 
            answer="Да, по умолчанию доступен 1 гб на пользователя, но не более 10 пользователей в организации." 
          />
        </div>
      </div>
      <EndBlock />
    </MainContent>
  )
}