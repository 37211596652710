import React, { useState, useRef, useEffect } from 'react';
import css from './index.module.css';
import axios from 'axios';
import { toast } from 'sonner';

export default function DemoPopup({ showModal, setShowModal }) {
  const [sended, setSended] = useState(false);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios.post(`https://api.leecyber.com/vSite/application`, formData, {
        headers: {
            'Content-Type': 'application/json'
        }})
        .then(response  => {
            toast.success("Отправили заявку");
            event.target.reset();
            localStorage.setItem('sendedForm', true);
            setShowModal(false);
            setSended(true);
        })
        .catch(error => {
            toast.error("Возникла проблема");
    });
  };

  return (
    <div className={showModal ? css.popup : `${css.popup} ${css.hidden}`}>
        <div className={css.bpopup} onClick={() => setShowModal(false)}></div>
        <div className={css.pblock}>
            {sended || localStorage.getItem('sendedForm') && localStorage.getItem('sendedForm') === true ? 
                <h6 className={css.header}>Вы уже отправили заявку 👀</h6>
             :    
                <form onSubmit={handleSubmit} className="form" method="post">
                    <h6 className={css.header}>Отправить заявку</h6>
                    
                    <div className={css.inputBlock}>
                        <label for="name">*Как к вам обращаться?</label>
                        <input type='text' placeholder='Как к вам обращаться?' name='name' required/>
                    </div>

                    <div className={css.inputBlock}>
                        <label for="phone">*Телефон</label>
                        <input type='text' placeholder='Укажите номер телефона для связи' name='phone' required/>
                    </div>

                    <div className={css.inputBlock}>
                        <label for="email">*Корпоративная почта</label>
                        <input type='text' placeholder='Укажите корпоративную почту' name='email' required/>
                    </div>

                    <div className={css.inputBlock}>
                        <label for="company">Компания</label>
                        <input type='text' placeholder='Компания' name='company' />
                    </div>

                    <div className={css.inputBlockCheck}>
                        <input type='checkbox' name='policy' required/>
                        <label for="policy" >Я даю свое согласие LeeCyber и его аффилированным лицам на обработку указанных мной в анкете персональных данных на условиях <a href="https://docs.leecyber.com/legal/privacy">Политики конфиденциальности</a> в целях получения обратной связи по заявке.*</label>
                    </div>

                    <div className={css.inputBlockCheck}>
                        <input type='checkbox' name='email' />
                        <label for="email">Я даю своё согласие на получение рекламных сообщений и иных материалов рекламного характера от LeeCyber и его аффилированных лиц, на условиях <a href="https://docs.leecyber.com/legal/privacy">Политики конфиденциальности.</a></label>
                    </div>

                    <button className={css.sendBtn} type="submit">Отправить</button>
                </form>
            }
        </div>
    </div>
  );
}