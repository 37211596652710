import React, { useState, useEffect } from "react";
import AI from '@assets/logo/ai.png';
import Analytics from '@assets/logo/analytics.png';
import Apps from '@assets/logo/apps.png';
import Check from '@assets/logo/check.png';
import Cloud from '@assets/logo/cloud.png';
import Dash from '@assets/logo/dash.png';
import Docs from '@assets/logo/docs.png';
import Drive from '@assets/logo/drive.png';
import ID from '@assets/logo/id.png';
import PAY from '@assets/logo/pay.png';
import Projects from '@assets/logo/projects.png';
import Shop from '@assets/logo/shop.png';
import Status from '@assets/logo/status.png';
import Tunnel from '@assets/logo/tunnel.png';
import Weboffice from '@assets/logo/weboffice.png';
import Secrets from '@assets/logo/secret.png';
import Notes from '@assets/logo/notes.png';
import css from './index.module.css';

export const Services = [
    {
        column: 1,
        name: "GPT",
        desc: "Подскажет и сделает",
        logo: AI
    },
    {
        column: 1,
        name: "Analytics",
        desc: "Посчитает и покажет",
        logo: Analytics
    },
    {
        column: 1,
        name: "Mini Apps",
        desc: "Интеграции в Dash",
        logo: Apps
    },
    {
        column: 1,
        name: "Secrets",
        desc: "Хранит секреты",
        logo: Secrets
    },
    {
        column: 1,
        name: "Tunnel",
        desc: "Безопасность",
        logo: Tunnel
    },
    {
        column: 2,
        name: "Notes",
        desc: "Хранит заметки",
        logo: Notes
    },
    {
        column: 2,
        name: "Projects",
        desc: "Работа в команде",
        logo: Projects
    },
    {
        column: 2,
        name: "Status",
        desc: "Мониторинг",
        logo: Status
    },
    {
        column: 2,
        name: "Shop",
        desc: "Корпоративный магазин",
        logo: Shop
    },
    {
        column: 2,
        name: "Cloud",
        desc: "Облако",
        logo: Cloud
    },
    {
        column: 3,
        name: "CorpForge",
        desc: "Документы",
        logo: Check
    },
    {
        column: 3,
        name: "Drive",
        desc: "Хранит файлы",
        logo: Drive
    },
    {
        column: 3,
        name: "HRM",
        desc: "Кадровый учёт",
        logo: ID
    },
    {
        column: 3,
        name: "Weboffice",
        desc: "Почта",
        logo: Weboffice
    },
    {
        column: 3,
        name: "Docs",
        desc: "Документооборот",
        logo: Docs
    }
];
  
export const SmoothLoopingServices = () => {
    const [columns, setColumns] = useState([[], [], []]);
  
    useEffect(() => {
      const newColumns = [[], [], []];
      Services.forEach(service => {
        newColumns[service.column - 1].push(service);
      });
      setColumns(newColumns);
    }, []);
  
    return (
      <div className={css.services}>
        {columns.map((columnServices, index) => (
          <ServicesColumn 
            key={index} 
            services={columnServices} 
            direction={index === 1 ? 'down' : 'up'} 
          />
        ))}
      </div>
    );
};

const ServicesColumn = ({ services, direction }) => {
    return (
      <div className={`${css.columnServices} ${direction === 'down' ? css.down : ''}`}>
        {services.concat(services).map((service, index) => (
          <div key={index} className={css.columnService}>
            <img src={service.logo} alt={service.name} />
            <a>{service.name}</a>
            <p>{service.desc}</p>
          </div>
        ))}
      </div>
    );
};