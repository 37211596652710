import React from 'react';
import { Helmet } from 'react-helmet';
import Logo from '@assets/logo/leecyber.png';

const SEO = ({ title, description, ogImage, ogUrl, favicon=Logo }) => {
    return (
        <Helmet>
        <html lang={`ru`} />
        <title>{title} — LeeCyber</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="бизнес-экосистема, управление бизнесом, контроль проектов, планирование задач, мониторинг процессов, трекер задач, совместная работа, облачное хранилище, документация, мобильное приложение, консоль управления, дашборд, статус проектов, LeeCyber, ликибер, HRM, аналитика, облачные технологии, корпоративный диск, CorpForge, CorpShop, кадровый учет, корпоративный магазин, корпоративные политики, веб-офис, виртуальный офис, API, CLI, бизнес-партнерства, business ecosystem, project management, task planning, process monitoring, task tracker, collaboration, cloud storage, documentation, mobile app, management console, dashboard, project status, analytics, cloud technologies, corporate drive, HR management, corporate policies, web office, virtual office, business partnerships" />
        
        <link rel="icon" href={favicon} />
        <link rel="apple-touch-icon" sizes="180x180" href={favicon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:locale" content={'ru_RU'} />
        <meta property="og:site_name" content="LeeCyber" />
        
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />

        <script type="application/ld+json">
        {`
        {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "LeeCyber",
            "url": "${ogUrl}",
            "logo": "${ogImage}",
            "description": "${description}"
        }
        `}
        </script>
        </Helmet>
    );
};

export default SEO;