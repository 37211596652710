import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";
import PromoBlock from "@widgets/promoBlock";
import FAQItem from "@widgets/faq";

import LogoDash from '@assets/logo/dash.png';
import LogoShop from '@assets/logo/shop.png';
import Logo from '@assets/logo/leecyber.png';
import SEO from '@widgets/seo';
import DemoPopup from '@widgets/demoPopup';

export default function CorpShop() {
  const [devId, setDevId] = useState(0);
  const [showModal, setShowModal] = useState(false);

  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />

      <SEO 
        title="CorpShop"
        description="Магазин для ваших сотрудников с виртуальной валютой."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/corpshop"
        favicon={LogoShop}
      />

      <div className={css.rbg} />
      <div className={css.hello}>
        <a className={css.serviceName}><img src={Logo} width={50} style={{ borderRadius: 10 }} /><img src={LogoDash} width={50} style={{ borderRadius: 10 }} /><img src={LogoShop} width={50} style={{ borderRadius: 10 }} /></a>
        <h1>Корпоративный магазин</h1>
        <a>Магазин для ваших сотрудников с виртуальной валютой</a>

        <div className={css.btns}>
          <Link className={css.connect} to={`https://dash.leecyber.com/`}>
            Попробовать сейчас
          </Link>
          <a onClick={() => setShowModal(true)}>Enterprise компаниям</a>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/corpshop.png'} />

        <div className={css.promoInfo}>
          <PromoBlock icon={<i class="fi fi-rr-usd-circle"></i>} title="Коины" desc="Начисляйте коины за выполненные задачи в Dashboard. Это повысит мотивацию к работе." />
          <PromoBlock icon={<i class="fi fi-rr-shop"></i>} title="Товары" desc="Добавляйте неограниченное количество товаров, которые можно купить за коины." />
          <PromoBlock icon={<i class="fi fi-rr-order-history"></i>} title="Контроль" desc="Контролируйте вовлеченность сотрудников в процессы компании." />
        </div>
      </div>

      <div className={css.blockInfo}>
        <div style={{ flex: 1, marginBottom: 20 }}>
          <h2>Возможности</h2>
        </div>

        <div className={css.dev}>
          <div className={css.devMenu}>
            <div className={devId === 0 && css.active} onClick={() => setDevId(0)}>Магазин</div>
            <div className={devId === 1 && css.active} onClick={() => setDevId(1)}>Личный кабинет</div>
            <div className={devId === 2 && css.active} onClick={() => setDevId(2)}>Управление заказами</div>
          </div>

          <div>
            {devId === 0 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/corpshop.png" />}
            {devId === 1 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/corpshop-lk.png" />}
            {devId === 2 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/corpshop-orders.png" />}
          </div>
        </div>
      </div>

      <div className={css.faq}>
        <h2>Отвечаем на вопросы</h2>

        <div className={css.faqItems}>
          <FAQItem 
            question="Как зарегистрироваться?" 
            answer="Зарегистрироваться в Dashboard можно с помощью вашего аккаунта LeeCyber.ID. После регистрации вы сможете создать организацию, чтобы объединить пользователей и управлять доступами." 
          />

          <FAQItem 
            question="Есть ли API?" 
            answer="Да, с его помощью можно интегрировать сервисы компании с другими системами." 
          />

          <FAQItem 
            question="Какой SLA у сервиса?" 
            answer="SLA на доступность сервиса Dashboard — 99,9%. Подробнее ознакомиться с условиями SLA можно в юридических документах." 
          />

          <FAQItem 
            question="Как перенести данные из других систем в Dashboard?" 
            answer="Вы можете перенести свои данные к нам при помощи API или разработанного нами инструмента миграции. Мы также можем подобрать партнёра, который проведет миграцию под ключ. Специалисты LeeCyber готовы ответить на ваши вопросы и помочь с миграцией — напишите нам." 
          />

          <FAQItem 
            question="Сколько можно добавить товаров?" 
            answer="Ограничений на количество товаров нет." 
          />

          <FAQItem 
            question="Сервис бесплатный?" 
            answer="Да, сервис входит в тарифный план Dashboard." 
          />
        </div>
      </div>
      <EndBlock />
    </MainContent>
  )
}