import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";
import PromoBlock from "@widgets/promoBlock";
import InfoBlock from "@widgets/infoBlock";
import PricingBlock from "@widgets/pricing";
import FAQItem from "@widgets/faq";

import LogoDash from '@assets/logo/dash.png';
import LogoProjects from '@assets/logo/projects.png';
import Logo from '@assets/logo/leecyber.png';

import SEO from '@widgets/seo';
import DemoPopup from '@widgets/demoPopup';

export default function Projects() {
  const [devId, setDevId] = useState(0);
  const [showModal, setShowModal] = useState(false);

  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />

      <SEO 
        title="Projects"
        description="Сервис для совместной работы и организации процессов в компании."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/projects"
        favicon={LogoProjects}
      />

      <div className={css.rbg} />
      <div className={css.hello}>
        <a className={css.serviceName}><img src={Logo} width={50} style={{ borderRadius: 10 }} /><img src={LogoDash} width={50} style={{ borderRadius: 10 }} /><img src={LogoProjects} width={50} style={{ borderRadius: 10 }} /></a>
        <h1>Projects</h1>
        <a>Сервис для совместной работы и организации процессов в компании.</a>

        <div className={css.btns}>
           <Link className={css.connect} to={`https://dash.leecyber.com/`}>
            Попробовать сейчас
          </Link>
          <a onClick={() => setShowModal(true)}>Enterprise компаниям</a>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/projects.png'} />

        <div className={css.promoInfo}>
          <PromoBlock 
            icon={<i class="fi fi-rr-universal-access"></i>} 
            title="Универсальный инструмент" 
            desc="Сделайте бизнес прозрачным — объедините процессы и проекты в одном инструменте." 
          />
          <PromoBlock 
            icon={<i class="fi fi-rr-cloud"></i>} 
            title="Единое пространство" 
            desc="Ваши отделы и подразделения могу работать в единой универсальной среде по разным методологиям." 
          />
          <PromoBlock 
            icon={<i class="fi fi-rr-bolt-auto"></i>} 
            title="Автоматизации процессов" 
            desc="Вы можете автоматизировать рутину и сэкономить время с помощью триггеров, автодействий и макросов." 
          />
       </div>
      </div>

      <div className={css.blockInfo}>
        <div style={{ flex: 1, marginBottom: 20 }}>
          <h2>Возможности</h2>
        </div>

        <div className={css.dev}>
          <div className={css.devMenu}>
            <div className={devId === 0 && css.active} onClick={() => setDevId(0)}>Создавайте задачи</div>
            <div className={devId === 1 && css.active} onClick={() => setDevId(1)}>Управляйте проектами</div>
            <div className={devId === 2 && css.active} onClick={() => setDevId(2)}>Используйте kanban</div>
          </div>

          <div>
            {devId === 0 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/projects-create.png" />}
            {devId === 1 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/projects-pr.png" />}
            {devId === 2 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/projects-kanban.png" />}
          </div>
        </div>
      </div>

      <div className={css.pricing}>
        <PricingBlock name="Starter" price={0} desc="За одного пользователя." children={
          <>
            <a><i class="fi fi-rr-user"></i> 5 пользователей</a>
            <a><i class="fi fi-rr-check-circle"></i> Полный доступ к сервису</a>
          </>
        } />

        <PricingBlock name="Teams" price={400} desc="За одного пользователя." color="#5776ba" children={
          <>
            <a><i class="fi fi-rr-user"></i> до 100 пользователей</a>
            <a><i class="fi fi-rr-check-circle"></i> Полный доступ к сервису</a>
          </>
        } />

        <PricingBlock name="Business" price={360} desc="За одного пользователя." color="#5776ba" borderColor="#5776ba" children={
          <>
            <a><i class="fi fi-rr-user"></i> 100+ пользователей</a>
            <a><i class="fi fi-rr-check-circle"></i> Полный доступ к сервису</a>
          </>
        } />
      </div>

      <div className={css.faq}>
        <h2>Отвечаем на вопросы</h2>

        <div className={css.faqItems}>
          <FAQItem 
            question="Как зарегистрироваться?" 
            answer="Зарегистрироваться в Dashboard можно с помощью вашего аккаунта LeeCyber.ID. После регистрации вы сможете создать организацию, чтобы объединить пользователей и управлять доступами." 
          />

          <FAQItem 
            question="Есть ли API?" 
            answer="Да, с его помощью можно интегрировать сервисы компании с другими системами." 
          />

          <FAQItem 
            question="Какой SLA у сервиса?" 
            answer="SLA на доступность сервиса Dashboard — 99,9%. Подробнее ознакомиться с условиями SLA можно в юридических документах." 
          />

          <FAQItem 
            question="Как перенести данные из других систем в Dashboard?" 
            answer="Вы можете перенести свои данные к нам при помощи API или разработанного нами инструмента миграции. Мы также можем подобрать партнёра, который проведет миграцию под ключ. Специалисты LeeCyber готовы ответить на ваши вопросы и помочь с миграцией — напишите нам." 
          />

          <FAQItem 
            question="Есть ли ограничения по объёму хранимой информации и количеству задач?" 
            answer="Нет, ограничений по объёму хранимых данных или обрабатываемых задач нет." 
          />
        </div>
      </div>
      <EndBlock />
    </MainContent>
  )
}