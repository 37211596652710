import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import css from './index.module.css';

import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";
import PromoBlock from "@widgets/promoBlock";
import PricingBlock from "@widgets/pricing";
import FAQItem from "@widgets/faq";

import LogoDash from '@assets/logo/dash.png';
import LogoStatus from '@assets/logo/status.png';
import Logo from '@assets/logo/leecyber.png';
import SEO from "@widgets/seo";
import DemoPopup from '@widgets/demoPopup';

export default function Status() {
  const [devId, setDevId] = useState(0);
  const [showModal, setShowModal] = useState(false);

  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />

      <SEO 
        title="Status"
        description="Инструмент для сбора, хранения и отображения метрик с ваших ресурсов."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/status"
        favicon={LogoStatus}
      />
      
      <div className={css.rbg} />
      <div className={css.hello}>
        <a className={css.serviceName}><img src={Logo} width={50} style={{ borderRadius: 10 }} /><img src={LogoDash} width={50} style={{ borderRadius: 10 }} /><img src={LogoStatus} width={50} style={{ borderRadius: 10 }} /></a>
        <h1>Мониторинг</h1>
        <a>Инструмент для сбора, хранения и отображения метрик</a>

        <div className={css.btns}>
           <Link className={css.connect} to={`https://dash.leecyber.com/`}>
            Попробовать сейчас
          </Link>
          <a onClick={() => setShowModal(true)}>Enterprise компаниям</a>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/status-public.png'} />

        <div className={css.promoInfo}>
          <PromoBlock 
            icon={<i class="fi fi-rr-bolt-auto"></i>} 
            title="Автоматический сбор метрик" 
            desc="Status собирает метрики ваших ресурсов автоматически, без дополнительных усилий с вашей стороны." 
          />
          <PromoBlock 
            icon={<i class="fi fi-rr-bell-notification-social-media"></i>} 
            title="Алерты и уведомления"
            desc="Вы можете настроить уведомления на достижение пороговых значений метрик. Получайте в Telegram или по SMS." 
          />
          <PromoBlock 
            icon={<i class="fi fi-rr-chart-pie-alt"></i>} 
            title="Сервисные дашборды" 
            desc="В Status есть заранее настроенные дашборды, вы можете подключить свои ресурсы за пару минут." 
          />
        </div>
      </div>

      <div className={css.blockInfo}>
        <div style={{ flex: 1, marginBottom: 20 }}>
          <h2>Возможности</h2>
        </div>

        <div className={css.dev}>
          <div className={css.devMenu}>
            <div className={devId === 0 && css.active} onClick={() => setDevId(0)}>Мониторы</div>
            <div className={devId === 1 && css.active} onClick={() => setDevId(1)}>Инциденты</div>
            <div className={devId === 2 && css.active} onClick={() => setDevId(2)}>Публичные страницы</div>
          </div>

          <div>
            {devId === 0 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/status-mons.png" />}
            {devId === 1 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/status-inc.png" />}
            {devId === 2 && <img className={css.imageI} src="https://cdn-a.leecyber.com/landing/status-public.png" />}
          </div>
        </div>
      </div>

      <div className={css.pricing}>
        <PricingBlock name="Starter" price={299} desc="Для маленьких организаций" children={
          <>
            <a><i class="fi fi-rr-user"></i> 250 подписчиков</a>
            <a><i class="fi fi-rr-check-circle"></i> 5 метрик</a>
            <a><i class="fi fi-rr-check-circle"></i> Обновление каждые 5 мин</a>
            <a><i class="fi fi-brands-telegram"></i> Уведомления в Telegram</a>
            <a><i class="fi fi-rr-at"></i> Уведомления Email</a>
            <a><i class="fi fi-rr-check-circle"></i> Базовая кастомизация</a>
            <a><i class="fi fi-rr-check-circle"></i> Доступ к REST API</a>
          </>
        } />

        <PricingBlock name="Teams" price={2999} desc="Для средних организаций" color="#5776ba" children={
          <>
            <a><i class="fi fi-rr-user"></i> 1000 подписчиков</a>
            <a><i class="fi fi-rr-check-circle"></i> 10 метрик</a>
            <a><i class="fi fi-rr-check-circle"></i> Обновление каждую 1 мин</a>
            <a><i class="fi fi-brands-telegram"></i> Уведомления в Telegram</a>
            <a><i class="fi fi-rr-at"></i> Уведомления Email</a>
            <a><i class="fi fi-rr-envelope"></i> Уведомления по SMS</a>
            <a><i class="fi fi-rr-site-alt"></i> Уведомления по вебхукам</a>
            <a><i class="fi fi-rr-customize-edit"></i> Базовая кастомизация</a>
            <a><i class="fi fi-rr-check-circle"></i> Доступ к REST API</a>
            <a><i class="fi fi-rr-url"></i> Пользовательский домен</a>
          </>
        } />

        <PricingBlock name="Business" price={9999} desc="Для крупных организаций" color="#5776ba" borderColor="#5776ba" children={
          <>
            <a><i class="fi fi-rr-user"></i> 25 000 подписчиков</a>
            <a><i class="fi fi-rr-check-circle"></i> 50 метрик</a>
            <a><i class="fi fi-rr-check-circle"></i> Обновление каждые 30 секунд</a>
            <a><i class="fi fi-brands-telegram"></i> Уведомления в Telegram</a>
            <a><i class="fi fi-rr-at"></i> Уведомления Email</a>
            <a><i class="fi fi-rr-envelope"></i> Уведомления по SMS</a>
            <a><i class="fi fi-rr-site-alt"></i> Уведомления по вебхукам</a>
            <a><i class="fi fi-rr-customize-edit"></i> Пользовательский CSS/JS</a>
            <a><i class="fi fi-rr-check-circle"></i> Доступ к REST API</a>
            <a><i class="fi fi-rr-url"></i> Пользовательский домен</a>
            <a><i class="fi fi-rr-check-circle"></i> Подписка на компоненты</a>
          </>
        } />
      </div>

      <div className={css.faq}>
        <h2>Отвечаем на вопросы</h2>

        <div className={css.faqItems}>
          <FAQItem 
            question="Как зарегистрироваться?" 
            answer="Зарегистрироваться в Dashboard можно с помощью вашего аккаунта LeeCyber.ID. После регистрации вы сможете создать организацию, чтобы объединить пользователей и управлять доступами." 
          />

          <FAQItem 
            question="Есть ли API?" 
            answer="Да, с его помощью можно интегрировать сервисы компании с другими системами." 
          />

          <FAQItem 
            question="Какой SLA у сервиса?" 
            answer="SLA на доступность сервиса Dashboard — 99,9%. Подробнее ознакомиться с условиями SLA можно в юридических документах." 
          />

          <FAQItem 
            question="Как перенести данные из других систем в Dashboard?" 
            answer="Вы можете перенести свои данные к нам при помощи API или разработанного нами инструмента миграции. Мы также можем подобрать партнёра, который проведет миграцию под ключ. Специалисты LeeCyber готовы ответить на ваши вопросы и помочь с миграцией — напишите нам." 
          />
        </div>
      </div>
      <EndBlock />
    </MainContent>
  )
}