import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';
import FAQItem from "@widgets/faq";
import SEO from '@widgets/seo';

export default function About() {
    const navigate = useNavigate();
    const [news, setNews] = useState([]);

    useEffect(() => {
        axios.get(`https://api.leecyber.com/vSite/news?limit=3`, {
        headers: {
            'Content-Type': 'application/json'
        }
        })
        .then(response  => {
        setNews(response.data);
        })
    }, []);

    function convertToDate(isoDate) {
        const date = new Date(isoDate);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat('ru-Ru', options).format(date);
    }

    const handleRedirect = (id) => {
        navigate(`/blog/${id}`);
    };
    
    return (
        <MainContent>
            <SEO 
                title="Компания"
                description="LeeCyber развивает сервисы, которые помогают сотням организаций решать задачи онлайн каждый день."
                ogImage="https://cdn-a.leecyber.com/landing/og.png"
                ogUrl="https://leecyber.com/about"
            />

            <div className={css.head}>
                <h1>LeeCyber развивает сервисы, которые <b>помогают сотням организаций</b> решать задачи онлайн каждый день.</h1>
                <a>Мы начали создавать LeeCyber в 2021 году, чтобы решить проблемы, с которыми сталкивается каждая компания. Хотя за последние 15 лет почти все технологии эволюционировали и перешли в облако, изменились методы работы. Мы были шокированы, узнав, что в подавляющем большинстве случаев у команд нет единой и понятной структуры. Причина этого заключалась в сложной миграции и недостаточной простоте использования, которые создают серьезные препятствия для внедрения более современного и безопасного подхода. <br /><br />Мы понимаем, что эффективные решения требуют внимания к удоству использования и надежности. Мы предоставляем услуги корпоративного уровня с удоством использования на уровне потребителя, независимо от того, являетесь ли вы IT администратором, который живет IT, или конечным пользователем, который просто хочет иметь возможность выполнять свою работу, где бы он не находился.</a>
            </div>

            <div className={css.news}>
                {news.map(newsBlock => (
                    <div className={css.newsBlock} key={newsBlock.id} onClick={() => handleRedirect(newsBlock.id)}>
                    <img src={`https://cdn-a.leecyber.com/news/${newsBlock.image}`} />
                    <a>{newsBlock.by} • {convertToDate(newsBlock.createdAt)}</a>
                    <h3>{newsBlock.name}</h3>
                    </div>
                ))}
            </div>

            <div className={css.faq}>
                <h2>Ценности компании</h2>

                <div className={css.faqItems}>
                <FAQItem 
                    question="Партнеры" 
                    answer="Наши партнеры – это часть команды оформленные в другой организации." 
                />

                <FAQItem 
                    question="Команда" 
                    answer="Слаженная командная работа – позитивный командный дух, отношение на уровне семьи." 
                />

                <FAQItem 
                    question="Энтузиазм" 
                    answer="Демонстрировать позитивное отношение, энергичность, смелость." 
                />

                <FAQItem 
                    question="Уважение" 
                    answer="Уважение – долг, которому всякий честный человек должен повиноваться с рождения!" 
                />
                </div>
            </div>
        </MainContent>
    )
}